import { useToast } from 'vue-toastification'

export default () => {
  const toast = useToast()

  const showMessage = (message: string, type = 'default') => {
    switch (type) {
      case 'error':
        toast.error(message)
        break
      case 'warning':
        toast.warning(message)
        break
      case 'success':
        toast.success(message)
        break
      default:
        toast(message)
    }
  }

  return {
    showMessage,
  }
}
