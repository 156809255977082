import { reactive } from 'vue'
import { GameStatus, TileStatus } from '../src/types'
import type { Board, Friend, Game, GameInviteStatus, Player, PlayerStats, State, Store, User } from '../src/types'
const solutions: string[] = ['crest', 'crack', 'peers', 'crane']
const validWords: string[] = ['crest', 'crack', 'peers', 'crane']
class MockStore implements Store {
  state: State = reactive({ user: { loggedIn: false } as User, gameLoaded: false, game: {} as Game, playerStats: {} as PlayerStats, invites: [] })
  allowedWords: string[]
  constructor() {
    console.log('****** MOCK STORE CREATED ********')
    this.state.user.loggedIn = false
    this.allowedWords = solutions.concat(validWords)
  }

  updateInviteStatus(id: string, status: GameInviteStatus): Promise<void> {
    throw new Error('Method not implemented.')
  }

  listenForInvite(): void {
    throw new Error('Method not implemented.')
  }

  inviteFriend(friend: Friend, gameCode: string): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async addFriend(friendId: string, friendName: string) {
    this.state.user.friends.push({ id: friendId, name: friendName })
    console.log(`Added Friend: ${friendName}`)
  }

  async loadUser(uid: string, cb: Function): Promise<void> {
    const testUser = {
      id: uid,
      email: 'example@email.com',
      displayName: 'Andrew',
      photoUrl: null,
      provider: 'google',
      loggedIn: true,
      stats: null,
      friends: [],
    }

    this.state.user = testUser
  }

  async finishGame(gameId: string, winner: string | null) {
    this.state.game.winner = winner
    this.state.game.status = GameStatus.FINISHED
  }

  async getGame(): Promise<boolean> {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async loadGame(gameId: string, updateGameState: Function) {
    updateGameState(this.state.game)
  }

  unloadGame(): void {
    this.state.gameLoaded = false
  }

  userLoggedIn(): boolean {
    return this.state.user?.loggedIn
  }

  generateId(length: number): string {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++)
      result += characters.charAt(Math.floor(Math.random() * charactersLength))

    return result
  }

  async createGame(gameId: string, creatorId: string): Promise<string> {
    if (gameId === '') gameId = this.generateId(6)

    const players: Record<string, Player> = {}

    players[creatorId] = {
      role: 'creator',
      name: 'Player',
    }
    const randomWord = solutions[Math.floor(Math.random() * solutions.length)]
    const newGame: Game = {
      id: gameId,
      solution: randomWord,
      players: {
        ...players,
      },
      createdAt: Date.now().toString(),
      finishedAt: null,
      creator: creatorId,
      status: GameStatus.NOT_STARTED,
      winner: null,
      startedAt: null,
    }

    this.state.game = newGame

    return gameId
  }

  async updateGameStatus(gameId: string, gameStatus: GameStatus): Promise<void> {
    this.state.game.status = gameStatus
  }

  async updateGameWinner(gameId: string, playerId: string): Promise<void> {
    this.state.game.winner = playerId
  }

  async updateGameFinishTime(): Promise<void> {
    this.state.game.finishedAt = Date.now().toString()
  }

  async updateBoardState(board: Board, gameId = this.state.game.id, playerId = this.state.user.id): Promise<void> {
    const boardPreview = []
    console.log({ board, gameId })
    for (const row of board.state) {
      let rowStr = ''
      for (const tile of row) {
        if (tile.status !== TileStatus.UNKNOWN)
          rowStr += tile.status[0]
      }
      if (rowStr !== '')
        boardPreview.push(rowStr)
    }
    this.state.game.players[playerId].board = board
    this.state.game.players[playerId].boardPreview = boardPreview
  }

  async addPlayerToGame(gameId: string, playerId: string): Promise<void> {
    this.state.game.players[playerId] = { role: 'joiner', name: 'Player' }
  }

  async createNewGame(): Promise<string> {
    const gameId = this.generateId(6)
    return await this.createGame(gameId, this.state.user.id)
  }
}

export default new MockStore()
