import { createApp } from 'vue'
import type { PluginOptions } from 'vue-toastification'
import Toast, { POSITION } from 'vue-toastification'
import './styles/reset.css'
import './styles/main.scss'
import 'vue-toastification/dist/index.css'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import router from './router'
import App from './App.vue'

if (process.env.NODE_ENV === 'production') console.log('PRODUCTION')

const app = createApp(App)

const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  maxToasts: 5,
  newestOnTop: true,
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
}

app.use(Toast, options)
app.use(VueLoading)
app.use(router)
app.mount('#app')
