import { reactive } from 'vue'
import useUtils from './useUtils'
import type { Keyboard, Tile } from '@/types'
import { KeyboardKeyStyle, TileStatus } from '@/types'

const { createKeyboard } = useUtils()

export default () => {
  const keyboard: Keyboard = reactive(createKeyboard())

  const updateKeyboardKeys = (row: Tile[]) => {
    for (const tile of row) {
      const keyToChange = keyboard.upperRow.find(key => key.key === tile.letter) ?? keyboard.middleRow.find(key => key.key === tile.letter) ?? keyboard.lowerRow.find(key => key.key === tile.letter)

      if (keyToChange == null) {
        console.error('Can\'t find key to change')
        continue
      }
      switch (tile.status) {
        case TileStatus.CORRECT:
          keyToChange.style = KeyboardKeyStyle.CORRECT
          break
        case TileStatus.PRESENT:
          keyToChange.style = (keyToChange.style !== KeyboardKeyStyle.CORRECT) ? KeyboardKeyStyle.PRESENT : keyToChange.style
          break
        case TileStatus.MISSING:
          keyToChange.style = (keyToChange.style === KeyboardKeyStyle.NORMAL) ? KeyboardKeyStyle.MISSING : keyToChange.style
          break
      }
    }
  }

  return {
    keyboard,
    updateKeyboardKeys,
  }
}
