
import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

let firebaseConfig = {}

if (process.env.NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyDi1QgP1p4fWa5G-qQOjuQwYin2WkWuvCs',
    authDomain: 'word-race-prod.firebaseapp.com',
    databaseURL: 'https://word-race-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'word-race-prod',
    storageBucket: 'word-race-prod.appspot.com',
    messagingSenderId: '842321305434',
    appId: '1:842321305434:web:a250292e4615c9e5835d63',
    measurementId: 'G-JPP6N54HM4',
  }
}
else {
  firebaseConfig = {
    apiKey: 'AIzaSyCdx8WXgew6_Lt8GGJ3sbX6Ld7VEoNcIYI',
    authDomain: 'word-race-dev-f06c9.firebaseapp.com',
    projectId: 'word-race-dev-f06c9',
    storageBucket: 'word-race-dev-f06c9.appspot.com',
    messagingSenderId: '312015038385',
    appId: '1:312015038385:web:42e1d7ed95ce4b81de7f90',
    measurementId: 'G-BH559J1R5Q',
    databaseURL: 'https://word-race-dev-f06c9-default-rtdb.europe-west1.firebasedatabase.app',
  }
}

fb.initializeApp(firebaseConfig)
// Change localhost to ip address to test on mobile
if (window.location.href.includes('localhost') || window.location.href.includes('192.')) {
  console.log('Using firebase emulator')
  fb.database().useEmulator('localhost', 9000)
  fb.firestore().useEmulator('localhost', 9001)
  fb.functions().useEmulator('localhost', 5001)
  // @ts-expect-error disableWarnings isn't found in the type for some reason
  fb.auth().useEmulator('http://localhost:9099', { disableWarnings: true })
}

export const db = fb.firestore()
export const rtdb = fb.database()
export const functions = fb.functions()
export const serverValue = fb.database.ServerValue
export const firebase = fb
export const auth = fb.auth()
