import firebase from 'firebase'
import { reactive } from 'vue'
import { GameInviteStatus, GameStatus, TileStatus } from './types'
import type { Board, Friend, Game, GameInvite, State, Store, User } from './types'
import { db, rtdb, serverValue } from '@/FirebaseConfig'
import solutions from '@/resources/solutions.json'
import validWords from '@/resources/validWord.json'
class FirebaseStore implements Store {
  state = reactive({ user: { loggedIn: false }, invites: [] as GameInvite[] }) as State
  gameRef: firebase.database.Reference
  allowedWords: string[]
  constructor() {
    console.log('****** FIREBASE STORE CREATED ********')
    this.state.user.loggedIn = false
    this.allowedWords = solutions.concat(validWords)
  }

  async addFriend(friendId: string, friendName: string) {
    await db.collection('users').doc(this.state.user.id).update({ friends: firebase.firestore.FieldValue.arrayUnion({ id: friendId, name: friendName } as Friend) })
  }

  async inviteFriend(invitee: Friend, gameCode: string) {
    const inviter: Friend = { id: this.state.user.id, name: this.state.user.displayName ?? 'Player' }
    await db.collection('invites').add({ invitee, inviter, gameCode, sentAt: firebase.firestore.FieldValue.serverTimestamp(), status: GameInviteStatus.PENDING })
  }

  async loadUser(uid: string, cb: Function) {
    db.collection('users').doc(uid)
      .onSnapshot((userDoc) => {
        if (userDoc.exists) {
          console.log('user found')
          this.state.user = userDoc.data() as User
          this.state.user.loggedIn = true
          this.state.user.id = uid
          console.log({ user: this.state.user })
          cb()
        }
      })
  }

  async listenForInvite() {
    if (!this.userLoggedIn()) return
    db.collection('invites').where('invitee.id', '==', this.state.user.id).where('status', '==', GameInviteStatus.PENDING).onSnapshot((snapshot) => {
      this.state.invites = []
      snapshot.forEach((doc) => {
        const invite = doc.data() as GameInvite
        invite.id = doc.id
        this.state.invites.push(invite)
      })
    })
  }

  async updateInviteStatus(id: string, status: GameInviteStatus) {
    await db.collection('invites').doc(id).update({ status })
  }

  async getGame(gameId: string): Promise<boolean> {
    const gameRef = rtdb.ref(`games/${gameId}`)

    const snapshot = await gameRef.get()

    this.state.game = snapshot.val() ?? {}

    return snapshot.exists()
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async loadGame(gameId: string, updateGameState: Function) {
    this.gameRef = rtdb.ref(`games/${gameId}`)
    this.gameRef.on('value', (snapshot) => {
      const data = snapshot.val()
      this.state.game = data
      this.state.gameLoaded = true
      updateGameState(data)
    })
  }

  unloadGame(): void {
    this.state.game = {} as Game
    if (this.gameRef)
      this.gameRef.off()
  }

  userLoggedIn(): boolean {
    return this.state.user?.loggedIn
  }

  /* These updates can be done in one call  */
  async updateGameStatus(gameId: string, gameStatus: GameStatus): Promise<void> {
    return await rtdb.ref(`games/${gameId}/status`).set(gameStatus)
  }

  async finishGame(gameId: string, winner: string | null) {
    return await rtdb.ref(`games/${gameId}`).update({ status: GameStatus.FINISHED, finishedAt: serverValue.TIMESTAMP, winner })
  }

  async updateBoardState(board: Board, gameId = this.state.game.id, playerId = this.state.user.id): Promise<void> {
    const ref = rtdb.ref(`games/${gameId}/players/${playerId}`)
    const boardPreview = []
    console.log({ board })
    for (const row of board.state) {
      let rowStr = ''
      for (const tile of row) {
        if (tile.status !== TileStatus.UNKNOWN)
          rowStr += tile.status[0]
      }
      if (rowStr !== '')
        boardPreview.push(rowStr)
    }
    await ref.update({ boardPreview, board })
  }
}

export default new FirebaseStore()
