import { reactive, ref } from 'vue'
import useToast from './useToast'
import useUtils from './useUtils'
import type { Board, Tile } from '@/types'
import { TileStatus } from '@/types'
type SaveBoardState = (board: Board) => void
type UpdateKeyboardKeys = (row: Tile[]) => void

const { showMessage } = useToast()
const { createEmptyBoard, ANIMATION_DELAY_MS } = useUtils()

export const useGameBoard = (saveBoardState: SaveBoardState, updateKeyboardKeys: UpdateKeyboardKeys, allowedWords: string[], ROWS = 5, COLS = 5) => {
  const board: Board = reactive(createEmptyBoard(ROWS, COLS))
  const SOLUTION = ref('')
  const currentWordValid = ref(true)
  const setSolution = (solution: string) => SOLUTION.value = solution

  const getCurrentRowIndex = () => {
    let rowIndex = 0
    let word = ''
    for (const row of board.state) {
      word = row.map(tile => tile.letter).join('')
      if (word.length < board.cols)
        break
      rowIndex++
    }
    // const colIndex = word.length

    return rowIndex
  }
  const startRowIndex = getCurrentRowIndex()

  const rowIndex = ref(startRowIndex)

  const getRowWord = (row: Array<Tile>) => row.map(tile => tile.letter).join('')
  const isRowFull = (row: Array<Tile>) => getRowWord(row).length >= board.cols

  const gameOver = () => rowIndex.value === board.rows || board.state[rowIndex.value - 1]?.every(tile => tile.status === TileStatus.CORRECT)

  const removeLetter = () => {
    if (gameOver()) return

    const row = board.state[rowIndex.value]

    const deleteIndex = getRowWord(row).length - 1

    if (deleteIndex < 0)
      return

    row[deleteIndex].letter = ''
    row[deleteIndex].status = TileStatus.EMPTY
  }

  const addLetter = (l: string) => {
    if (gameOver()) return

    const row = board.state[rowIndex.value]

    if (isRowFull(row))
      return

    for (const tile of row) {
      if (tile.letter === '') {
        tile.letter = l
        tile.status = TileStatus.UNKNOWN
        break
      }
    }
  }

  const isValidWord = (word: string) => {
    return allowedWords.includes(word)
  }

  const isCurrentWordValid = () => {
    if (gameOver()) return true

    const rowWord = getRowWord(board.state[rowIndex.value])
    if (rowWord.length < board.cols)
      return true

    return isValidWord(rowWord)
  }

  const submitRow = () => {
    if (gameOver()) return false
    // Change letter colour
    const rowWord = getRowWord(board.state[rowIndex.value])
    const solutionArray = Array.from(SOLUTION.value)

    if (rowWord.length < board.cols) {
      console.log('Not enough letters')
      showMessage('Not enough letters', 'warning')
      return false
    }

    if (!isValidWord(rowWord)) {
      console.log(`invalid word: ${rowWord}`)
      showMessage('That\'s not a word!', 'warning')
      return false
    }

    // Check for correct tiles
    for (let i = 0; i < board.state[rowIndex.value].length; i++) {
      const tile = board.state[rowIndex.value][i]
      tile.status = TileStatus.MISSING // Set tile to missing initially

      if (solutionArray[i] === tile.letter) {
        tile.status = TileStatus.CORRECT
        solutionArray[i] = '*'
      }
    }
    // Check for present tiles
    for (let i = 0; i < board.state[rowIndex.value].length; i++) {
      const tile = board.state[rowIndex.value][i]
      const letterIndex = solutionArray.indexOf(tile.letter)

      if (tile.status === TileStatus.CORRECT) continue

      if (letterIndex !== -1) {
        tile.status = TileStatus.PRESENT
        solutionArray[letterIndex] = '*'
      }
    }

    rowIndex.value++

    return true
  }

  const updateGameBoard = (key: string) => {
    switch (key) {
      case 'ENTER':
        if (submitRow()) {
          // Could check if player has won and increase timeout delay here?
          setTimeout(() => {
            updateKeyboardKeys(board.state[rowIndex.value - 1])
            saveBoardState(board)
          }, ANIMATION_DELAY_MS * (COLS - 1))
        }

        break
      case 'DELETE':
        removeLetter()
        break
      default:
        addLetter(key)
    }
    currentWordValid.value = isCurrentWordValid()
  }

  const clearGameBoard = () => {
    board.state = createEmptyBoard().state
    rowIndex.value = 0
  }

  //   const counterPositiveOrNegitive = computed(() =>
  //     counter.value >= 0 ? ' Positive' : 'Negitive',
  //   )
  return {
    updateGameBoard,
    board,
    setSolution,
    clearGameBoard,
    currentWordValid,
  }
}
