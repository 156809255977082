import type { Board, Game, Store } from './types'
import { GameStatus, TileStatus } from './types'
export default class GameController {
  GAME_ID: string
  ROWS: number
  COLS: number
  game = {} as Game
  userId: string
  MAX_PLAYERS = 2
  store: Store
  constructor(gameId: string, userId: string, store: Store, rows = 5, cols = 5) {
    this.GAME_ID = gameId
    this.ROWS = rows
    this.COLS = cols
    this.userId = userId
    this.store = store
  }

  getUserPlayer() {
    if (!this.userPlayerJoined())
      throw new Error('User Player has not joined')

    return this.game.players[this.userId]
  }

  getOpponentPlayer() {
    if (!this.opponentHasJoined())
      throw new Error('Opponent has not joined')

    const opponentId = Object.keys(this.game.players).find(id => id !== this.userId) ?? ''

    return this.game.players[opponentId]
  }

  userPlayerJoined() {
    return Object.keys(this.game.players).includes(this.userId)
  }

  getNumberOfPlayers() {
    return Object.keys(this.game.players).length
  }

  gameIsFull() {
    return this.getNumberOfPlayers() === this.MAX_PLAYERS
  }

  opponentHasJoined() {
    return Object.keys(this.game.players).some(id => id !== this.userId)
  }

  setGameStatus(gs: GameStatus) {
    this.game.status = gs
  }

  gameStateUpdated(newGameState: Game) {
    this.game = newGameState

    switch (this.game.status) {
      case GameStatus.NOT_STARTED:
        this.handleGameNotStarted()
        break
      case GameStatus.WAITING_FOR_OPPONENT:
        this.handleWaitingForOpponent()
        break
      case GameStatus.IN_PROGRESS:
        this.handleInProgress()
        break
    }
  }

  private handleInProgress() {
    // this won't work if I switch to a live board preview
    const boardPreview = this.getUserPlayer().boardPreview
    if (boardPreview) {
      const userWon = boardPreview[boardPreview.length - 1]?.split('').every(status => status === 'C') ?? false

      if (userWon) {
        this.store.finishGame(this.GAME_ID, this.userId)
        return
      }

      const draw = boardPreview.length === this.ROWS && this.getOpponentPlayer().boardPreview?.length === this.ROWS

      if (draw)
        this.store.finishGame(this.GAME_ID, null)
    }
  }

  private getCurrentRowIndex(boardState: Board) {
    let rowIndex = 0
    for (const row of boardState.state) {
      if (row.some(tile => tile.status === TileStatus.UNKNOWN))
        return rowIndex
      rowIndex++
    }

    return rowIndex
  }

  private handleWaitingForOpponent() {
    if (this.getUserPlayer().role === 'joiner') {
      console.log('joiner is here!')
      this.store.updateGameStatus(this.GAME_ID, GameStatus.IN_PROGRESS)
    }
  }

  private handleGameNotStarted() {
    if (this.getUserPlayer().role === 'creator') {
      console.log('player is creator')
      if (!this.gameIsFull()) {
        this.store.updateGameStatus(this.GAME_ID, GameStatus.WAITING_FOR_OPPONENT)
        console.log('waiting for opponent')
      }
      else {
        this.store.updateGameStatus(this.GAME_ID, GameStatus.IN_PROGRESS)
        console.log('Game in progress')
      }
    }
    else {
      console.log('player is joiner')
      this.store.updateGameStatus(this.GAME_ID, GameStatus.IN_PROGRESS)
      console.log('Game in progress')
    }
  }
}
