import type { Board, Keyboard, Tile } from '@/types'
import { KeyboardKeyStyle, TileStatus } from '@/types'

export default () => {
  const ANIMATION_DELAY_MS = 500

  const createEmptyBoard = (ROWS = 5, COLS = 5): Board => {
    const tiles: Array<Array<Tile>> = []
    for (let i = 0; i < ROWS; i++) {
      const colArray: Array<Tile> = []

      for (let col = 0; col < COLS; col++)
        colArray.push({ letter: '', status: TileStatus.UNKNOWN })

      tiles.push(colArray)
    }

    return { state: tiles, rows: ROWS, cols: COLS }
  }

  const createKeyboard = () => {
    const keys = {} as Keyboard
    const upperRow = Array.from('qwertyuiop')
    const middleRow = Array.from('asdfghjkl')
    const lowerRow = Array.from('zxcvbnm')

    keys.upperRow = upperRow.map((letter) => { return { key: letter, style: KeyboardKeyStyle.NORMAL } })
    keys.middleRow = middleRow.map((letter) => { return { key: letter, style: KeyboardKeyStyle.NORMAL } })
    keys.lowerRow = lowerRow.map((letter) => { return { key: letter, style: KeyboardKeyStyle.NORMAL } })

    return keys
  }

  const formatSeconds = (seconds: number) => {
    const minutesPart = Math.floor(seconds / 60)
    const secondsPart = seconds - (minutesPart * 60)

    return `${(`0${minutesPart}`).slice(-2)}:${(`0${secondsPart}`).slice(-2)}`
  }

  const copyToClipboardAsync = (text: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(text)
    return Promise.reject(new Error('The Clipboard API is not available.'))
  }

  const shareText = (title: string, text: string) => {
    if (navigator.canShare && navigator.canShare({ text })) {
      navigator.share({
        title,
        text,
      })
        .then(() => console.log('Share was successful.'))
        .catch(error => console.log('Sharing failed', error))
    }
    else {
      console.log('Your system doesn\'t support sharing files.')
    }
  }

  return { createEmptyBoard, createKeyboard, formatSeconds, copyToClipboardAsync, shareText, ANIMATION_DELAY_MS }
}
