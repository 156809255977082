import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/index.vue'
import Game from '@/pages/game.vue'
import PlayerStats from '@/pages/stats.vue'
import Test from '@/pages/test.vue'
import Components from '@/pages/components.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/play/:gameId',
    name: 'game',
    component: Game,
  },
  {
    path: '/stats',
    name: 'stats',
    component: PlayerStats,
  },
  {
    path: '/dev/components',
    name: 'components',
    component: Components,
  },
  {
    path: '/test',
    name: 'test',
    component: Test,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
