export enum TileStatus {
  EMPTY = 'EMPTY',
  UNKNOWN = 'UNKNOWN',
  MISSING = 'MISSING',
  PRESENT = 'PRESENT',
  CORRECT = 'CORRECT',
}

export enum GameStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  WAITING_FOR_OPPONENT = 'WAITING_FOR_OPPONENT',
}

export enum KeyboardKeyStyle {
  NORMAL = 'normal',
  CORRECT = 'correct',
  PRESENT = 'present',
  MISSING = 'missing',
}

export enum GameInviteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface Tile {
  letter: string
  status: TileStatus

}

export interface Board {
  state: Tile[][]
  rows: number
  cols: number
}

export interface KeyboardKey {
  key: string
  style: KeyboardKeyStyle
}

export interface Keyboard {
  upperRow: Array<KeyboardKey>
  middleRow: Array<KeyboardKey>
  lowerRow: Array<KeyboardKey>

}

export interface Game {
  id: string
  solution: string
  creator: string
  createdAt: string | number | any
  finishedAt: string | number | null
  startedAt: string | number | null
  status: GameStatus
  winner: string | null
  players: Record<string, Player>
}

export interface Friend {
  id: string
  name: string
}

export interface GameInvite {
  id: string
  inviter: Friend
  invitee: Friend
  gameCode: string
  sentAt: string
  status: GameInviteStatus
}

export interface User {
  id: string
  email: string | null
  displayName: string | null
  photoUrl: string | null
  provider: string | null
  stats: PlayerStats | null
  loggedIn: boolean
  friends: Friend[]
}

export interface Player {
  role: string
  name: string
  boardPreview?: string[]
  board?: Board
}

export interface WordFrequencyMap {
  word: string
  count: number
}

export interface PlayerStats {
  gamesPlayed: number
  wins: number
  losses: number
  draws: number
  streak: number
  maxStreak: number
  fastestWin: number | null
  averageWin: number | null
  openers: WordFrequencyMap[]
  lastGamePlayed: string | null
}

export interface State {
  playerStats: PlayerStats
  gameLoaded: boolean
  game: Game
  user: User
  invites: GameInvite[]
}

export interface Store {
  state: State
  allowedWords: string[]
  getGame(id: string): Promise<boolean>
  // eslint-disable-next-line @typescript-eslint/ban-types
  loadGame(id: string, updateGameState: Function): Promise<void>
  unloadGame(): void
  userLoggedIn(): boolean
  updateGameStatus(gameId: string, gameStatus: GameStatus): Promise<void>
  finishGame(gameId: string, winner: string | null): Promise<void>
  updateBoardState(board: Board, gameId?: string, playerId?: string): Promise<void>
  loadUser(uid: string, cb: Function): Promise<void>
  addFriend(friendId: string, friendName: string): Promise<void>
  updateInviteStatus(id: string, status: GameInviteStatus): Promise<void>
  listenForInvite(): void
  inviteFriend(friend: Friend, gameCode: string): Promise<void>
}
